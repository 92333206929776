import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { AxiosApiClient, CurrentUserDto, UsersApi } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { TypeSchema } from "@smallstack/typesystem";
import { FormComponent, SchemaFormTableComponent } from "@smallstack/widget-core";
import { AxiosResponse } from "axios";
import { UserService } from "../../services/user.service";

@Component({
  selector: "smallstack-login",
  templateUrl: "./login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormComponent, SchemaFormTableComponent, NgClass, LoadingElementDirective]
})
export class LoginComponent {
  @Output()
  public readonly afterLogin: EventEmitter<void> = new EventEmitter();

  protected formSchema: TypeSchema = {
    type: "object",
    properties: {
      email: {
        type: "string",
        format: "email",
        title: "E-Mail"
      },
      password: {
        type: "string",
        format: "password",
        title: "Passwort",
        minLength: 8
      }
    }
  };

  protected loginData: { email: string; password: string };

  constructor(
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  public login(): () => Promise<void> {
    return async (): Promise<void> => {
      try {
        const registerResponse: AxiosResponse<CurrentUserDto> = await this.axiosApiClient
          .get(UsersApi)
          .authenticateUserWithPassword({
            loginUserDto: { email: this.loginData.email, password: this.loginData.password }
          });
        if (registerResponse.status === 201) {
          this.userService.setCurrentUser(registerResponse.data);
          this.afterLogin.emit();
        } else {
          this.notificationService.showStandardErrorPopup(registerResponse.data as any);
        }
      } catch (e) {
        this.notificationService.showStandardErrorPopup(e);
      }
    };
  }
}
